// Generated by Framer (209e682)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, Container, getFonts, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";
import HeroImage from "../canvasComponent/t_S7qtE9F";
import FeaturesAppL from "../canvasComponent/xc1GAsiYj";
import Mailchimp from "https://framerusercontent.com/modules/0ZSF8VJcL5AhXebKiUw3/nbsZGufHZBGrLw7CNirb/Mailchimp.js";
const HeroImageFonts = getFonts(HeroImage);
const FeaturesAppLFonts = getFonts(FeaturesAppL);
const MailchimpFonts = getFonts(Mailchimp);

const cycleOrder = ["WQLkyLRf1"];

const breakpoints = {}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"WQLkyLRf1": "framer-v-72rtr7"};

if (isBrowser) removeHiddenBreakpointLayers("WQLkyLRf1", breakpoints, variantClassNames);

const humanReadableVariantMap = {};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style, className, width, height, layoutId, variant: outerVariant = "WQLkyLRf1", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "WQLkyLRf1", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div className="framer-LjifT" style={{"display": "contents"}}>
<motion.div {...restProps} className={cx("framer-72rtr7", className)} style={{...style}} name="Desktop" transition={transition} ref={ref} {...addVariantProps("WQLkyLRf1")}><Container className="framer-1fe47bc-container"  transition={transition} {...addVariantProps("fbjlMErWs-container")}><HeroImage width="100%" height="100%" layoutId="fbjlMErWs" id="fbjlMErWs" variant="LdbrSPQdL" style={{"width": "100%", "height": "100%"}} transition={transition} {...addVariantProps("fbjlMErWs")}/></Container><Container className="framer-1hyl8st-container"  transition={transition} {...addVariantProps("rxJrChjqb-container")}><FeaturesAppL width="100%" height="100%" layoutId="rxJrChjqb" id="rxJrChjqb" variant="jGsCWvq0Y" style={{"width": "100%"}} transition={transition} {...addVariantProps("rxJrChjqb")}/></Container><Container className="framer-aee5eh-container"  transformTemplate={transformTemplate("x")} transition={transition} {...addVariantProps("m5o0kAflx-container")}><Mailchimp width="100%" height="100%" layoutId="m5o0kAflx" id="m5o0kAflx" url="" layout="horizontal" input={{"placeholder": "email@framer.com", "placeholderColor": "rgba(0, 0, 0, 0.3)", "value": "", "fill": "rgb(235, 235, 235)", "color": "rgb(0, 0, 0)", "error": "rgb(238, 68, 68)"}} button={{"label": "Sign Up", "fontWeight": 600, "fill": "rgb(0, 0, 0)", "color": "rgb(255, 255, 255)"}} font={false} fontFamily="Inter" fontWeight={400} fontSize={16} padding={15} paddingPerSide={false} paddingTop={15} paddingRight={15} paddingBottom={15} paddingLeft={15} borderRadius={8} isMixedBorderRadius={false} topLeftRadius={8} topRightRadius={8} bottomRightRadius={8} bottomLeftRadius={8} gap={15} style={{"width": "100%"}} transition={transition} {...addVariantProps("m5o0kAflx")}/></Container></motion.div>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-LjifT [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-LjifT .framer-72rtr7 { position: relative; overflow: hidden; width: 1200px; height: 1532px; background-color: #ffffff; }", ".framer-LjifT .framer-1fe47bc-container { position: absolute; height: 733px; right: 0px; left: 0px; top: 0px; flex: none; }", ".framer-LjifT .framer-1hyl8st-container { position: absolute; height: auto; right: 0px; left: 0px; top: 757px; flex: none; }", ".framer-LjifT .framer-aee5eh-container { position: absolute; width: 430px; height: auto; left: 50%; top: 1350px; flex: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1532
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FrameraugiA20Il: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FrameraugiA20Il;

FrameraugiA20Il.displayName = "Home";

FrameraugiA20Il.defaultProps = {"width": 1200, "height": 1532};

addFonts(FrameraugiA20Il, [...HeroImageFonts, ...FeaturesAppLFonts, ...MailchimpFonts]);